.imageContainer {
    height: 5rem;
    width: 5rem;
    overflow: hidden;
    border-radius: 100%;
}

.image {
    left: 0;
    position: absolute;
    width: 100%;
    min-height: 100%;
}

.lcvScoreContainer {
    height: 1.85rem;
}

.callContainer {
    width: 140px;
}

.formCheckInput input {
    margin-top: 0.35rem;
}

.cardContainer {
    border-radius: 0.5rem !important;
}
