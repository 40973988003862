.formContainer {
    max-width: 450px;
}

.welcomePageContainer {
    max-width: 700px;
}

.formLabel {
    width: 30%;
}

.formControl {
    width: 70%;
}

@media only screen and (max-width: 767px) {
    .formLabel,
    .formControl {
        width: 100%;
    }
}
