:root {
    --bs-primary-rgb: 243, 182, 251 !important;
    --bs-dark-rgb: 46, 38, 108 !important;
    --primary-orange: #ee523b;
    --text-pink: #f3b6fb;
    --bg-light-grey: #eeefff;
    --primary-purple: #351f75;
    --disabled: #9b9b9b;
}

.btn-primary {
    background-color: rgb(var(--bs-primary-rgb)) !important;
    border-color: rgb(var(--bs-primary-rgb)) !important;
}

.btn-secondary {
    background-color: transparent !important;
    color: rgb(var(--bs-primary-rgb)) !important;
    border-color: rgb(var(--bs-primary-rgb)) !important;
}

.btn {
    -webkit-transition: 0.1s opacity linear !important;
    -moz-transition: 0.1s opacity linear !important;
    -o-transition: 0.1s opacity linear !important;
    transition: 0.1s opacity linear !important;
}

.btn:hover {
    opacity: 0.8;
}

.App {
    background-color: var(--primary-purple);
    color: white;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

.progress {
    height: 1.5rem !important;
}

.vh-50 {
    height: 50vh;
}

.card-title {
    color: rgb(var(--bs-dark-rgb));
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.fs-7 {
    font-size: 0.85rem !important;
}

.text-pink {
    color: var(--text-pink);
}

.text-purple {
    color: var(--primary-purple);
}

.bg-light-grey {
    background-color: var(--bg-light-grey) !important;
}

.border-purple {
    border: 1px solid var(--primary-purple);
}

.border-disabled {
    border: 1px solid var(--disabled);
}

.bg-dark-purple {
    background-color: var(--primary-purple);
}

.bg-purple {
    background-color: rgba(53, 31, 117, 0.15);
}

.bg-purple-secondary {
    background-color: rgba(49, 35, 112, 0.1);
}

.accordion-item,
.accordion-button {
    background-color: var(--bg-light-grey) !important;
}

hr {
    height: 1px !important;
    opacity: 1 !important;
}

.form-check-input:disabled {
    background-color: grey !important;
    border-color: grey !important;
}

.legislator-max-width {
    max-width: 800px;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.step-icon-desktop {
    font-size: 20px;
    width: 18px;
    height: 18px;
}

.step-icon-mobile {
    font-size: 12px;
    width: 18px;
    height: 18px;
}

.mobileScrollSpy {
    padding: 1rem 0;
    z-index: 101;
    background-color: rgb(var(--bs-dark-rgb));
    bottom: 0;
    left: 0;
}
